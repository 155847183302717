<template>
  <div class="modal-wrapper" :class="{ 'active': isVisible}">
    <div class="content-modal" :class="{ 'active': isVisible, [marker.type]: true, 'service-modal': isServiceMarker() }">
      <div class="content-header modal-header d-flex justify-content-between align-items-center mb-5">
        <div class="left-content d-flex align-items-center">
          <div class="step-circle">
            <svg v-if="isServiceMarker()" id="contactIcon" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 135.3 169.9">
              <path class="cls-1" d="M82.7,166.3c11.8-1.2,23.5-3,34.4-7.8,1.3-.6,2.6-1.2,3.9-1.9,7.6-4.2,12.3-9.2,9.8-20.2-4-17.9-17.2-32.8-34.4-36.4-1.8-.4-4.2,0-5.8,1-15.6,8.9-31.1,8.7-46.7.1-1.7-.9-4.2-1.4-6.1-1-19.7,4.6-34.3,22.7-34.9,42.7-.1,4.3,1.7,7.7,5,10,23,15.9,69.8,13.9,74.7,13.4ZM69.8,98.4c-1.1-1.4-1.8-2.3-2.4-3.3-3-5-.7-12.3,4.8-13.3,4.7-.8,9.8-.4,14.5.5,4.1.8,7.8.2,9.8-3.6,1.2-2.4,2.4-4.9,3.2-7.5,6-18.4-3.1-37.4-20.8-43.7-17.9-6.4-36.7,2.4-43.8,20.5-6.4,16.2,2.7,38.8,19,47.3,4.8,2.5,9.8,3.8,15.8,3ZM106.5,70.7c-1.8,3.3,3,8.7-2.1,11.7-3.8,2.2-8.4,3.1-12.7,4.6-.9.3-1.8.5-2.7.5-1,0-2.3.1-2.9-.4-3.6-2.9-7.8-1.7-11.6-1.3-1.5.2-3.5,2.6-3.7,4.2-.2,1.6,1.4,4.6,2.6,4.8,3.6.7,7.4.7,11.1.1,3.2-.5,6.2-2,9.3-3.1,4.7-1.7,9.4-3.6,14.2-5.3,1.5-.5,3.2-.9,4.6-.6,6.2,1.3,11.1-2.1,11.2-8.4.1-5.7.3-11.4-.1-17.1-.1-2.1-1.7-4.1-2.7-6.2-.7-1.6-2-3-2.1-4.6C115.5,18.8,85.6-1.6,57.4,4.2c-21.2,4.4-38.5,21.9-41.4,43.3-.4,3.1-.9,5.7-3.5,7.8-1.1.9-1.8,3-1.8,4.5-.2,6.1-.3,12.2,0,18.3.2,4.9,4.1,8.1,8.9,7.9,5-.2,8.2-3.4,8.3-8.5.1-5.7.2-11.4-.1-17.1-.1-2-1.6-3.9-2.3-5.9-.7-1.7-1.9-3.5-1.7-5,2.3-16.5,11.2-28.2,26.4-34.9,26-11.4,56.7,5.8,60.8,33.9.3,2.4.3,4.3-1.9,6.2-1.5,1.4-2.5,12.2-2.5,16Z"/>
            </svg>
            <div v-else class="step-circle-inner">
              <span>{{ marker.step }}</span>
            </div>
          </div>
          <div class="content-modal-header">
            <h2>{{ marker.contentTitle }}</h2>
          </div>
        </div>

        <button @click="closeModal" class="close-button">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30 10L10 30" stroke="#3F4043" stroke-width="2" stroke-linecap="square"/>
            <path d="M10 10L30 30" stroke="#3F4043" stroke-width="2" stroke-linecap="square"/>
          </svg>
        </button>
      </div>
      <div class="content-body">
        <div class="content-and-first-image">
          <template v-if="isServiceMarker()">
            <video
                :src="setServiceMarkerMedia()"
                :ref="`videoElement-service-${marker.id}`"
                loop
                muted
                controls
                class="first-video single service"></video>
          </template>
          <template v-if="marker.images.length > 0">
            <img v-if="isImage(marker.images[0])" :src="getFullImageUrl(marker.images[0])" class="first-image"
                 :class="{ 'single': marker.images.length === 1 }" :alt="marker.title + 'image' ? marker.title : 'content-title'" />
            <video
                v-else-if="isVideo(marker.images[0]) && !isServiceMarker()"
                :ref="`videoElement-${marker.id}-0`"
                :src="getFullImageUrl(marker.images[0])"
                loop
                muted
                controls
            class="first-video"></video>
          </template>
          <div v-html="marker.content" class="content-text" :class="marker.type" v-if="!isSalesShowroom"></div>
        </div>

          <div v-if="marker.images.length > 1" class="additional-images">
            <template v-for="(url, index) in marker.images.slice(1)" :key="index">
              <img v-if="isImage(url)" :src="getFullImageUrl(url)" class="content-image" :ref="setImgRef"
                   :alt="marker.title + 'image' ? marker.title : 'content-title'"/>
              <video v-else-if="isVideo(url)"
                     :src="getFullImageUrl(url)"
                     controls
                     loop
                     muted
                     class="content-video"
                     :ref="`videoElement-${marker.id}-${index + 100}`">
              </video>
            </template>
          </div>

        <div v-if="isSalesShowroom && marker.type !== 'data-flow' && marker.type !== 'goods-flow'" class="content-actions content-link mt-5 d-flex flex-column justify-content-center">
          <a class="btn content-btn service-btn mb-3" :href="serviceEndToEndSolutionsUrl" target="_blank">
            {{ $t('btn.serviceEndToEndSolutions') }}
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.00002 20H34.1667" stroke="#182983" stroke-width="1.66667" stroke-linecap="square"
                    stroke-linejoin="round"/>
              <path d="M19.9999 5.00172L35.0694 20.0712L19.9999 35.1406" stroke="#182983" stroke-width="1.66667"
                    stroke-linecap="square"/>
            </svg>
          </a>
          <a class="btn content-btn service-btn" :href="serviceMaterialHandlingUrl" target="_blank">
            {{ $t('btn.serviceSmartMaterialHandling') }}
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.00002 20H34.1667" stroke="#182983" stroke-width="1.66667" stroke-linecap="square"
                    stroke-linejoin="round"/>
              <path d="M19.9999 5.00172L35.0694 20.0712L19.9999 35.1406" stroke="#182983" stroke-width="1.66667"
                    stroke-linecap="square"/>
            </svg>
          </a>
        </div>
        <div v-if="marker.link.url && contentModalLinksVisible && !isServiceMarker() && marker.type !== 'data-flow'"
             class="content-link mt-5 d-flex justify-content-center">
          <a :href="finalLinkUrl"
             :target="marker.link.target"
             :title="marker.link.title"
             class="btn content-btn"
             :class="[marker.link.class, marker.type]"
          >
            {{ marker.link.title && marker.link.title !== "" ? marker.link.title : $t('btn.contentModal') }}
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.00002 20H34.1667" stroke="#182983" stroke-width="1.66667" stroke-linecap="square"
                    stroke-linejoin="round"/>
              <path d="M19.9999 5.00172L35.0694 20.0712L19.9999 35.1406" stroke="#182983" stroke-width="1.66667"
                    stroke-linecap="square"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['close'],
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    position: {
      type: Object,
      required: false,
      default: () => ({top: '50%', left: '50%'})
    },
    marker: {
      type: Object,
      required: true
    }
  },
  computed: {
    isOfflineBuild() {
      return process.env.VUE_APP_OFFLINE_BUILD === 'true';
    },
    isInlineFrame() {
      console.log('process.env.VUE_APP_INLINE_BUILD: ', process.env.VUE_APP_INLINE_BUILD);
      return process.env.VUE_APP_INLINE_BUILD === 'true';
    },
    serviceMaterialHandlingUrl() {
      const finalUriLang = this.$i18n.locale === 'en' ? 'en-us/' : (this.$i18n.locale === 'fr' ? 'fr-ch/' : '');
      const servicePath = this.$i18n.locale === 'fr' ? 'manipulation-de-materiel' : 'material-handling';
      return `https://www.stoecklin.com/${finalUriLang}services/${servicePath}`;
    },
    serviceEndToEndSolutionsUrl() {
      const finalUriLang = this.$i18n.locale === 'en' ? 'en-us/' : (this.$i18n.locale === 'fr' ? 'fr-ch/' : '');
      return `https://www.stoecklin.com/${finalUriLang}services/solutions`;
    },
    isSalesShowroom() {
      return process.env.VUE_APP_SALES_VARIANT === 'true';
    },
    contentModalLinksVisible() {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('contentlinks') !== 'false';
    },
    finalLinkUrl() {
      return this.marker.link.url;
    },
    modalStyle() {
      return {
        top: this.marker.position.top,
        left: this.marker.position.left,
        transform: 'translate(-50%, -50%)' // Um das Modal zu zentrieren
      };
    },
  },
  methods: {
    isImage(url) {
      return /\.(jpg|jpeg|png|gif)$/i.test(url);
    },
    isVideo(url) {
      return /\.(mp4|webm|ogg)$/i.test(url);
    },
    isServiceMarker() {
      return this.marker.title === 'Services' && this.marker.type === 'service';
    },
    setServiceMarkerMedia() {
      // get static image from assets
      let imageName = '09_Services_Film_KeyV_web';
      const imagesContext = require.context('../assets/static/content/media/', true, /\.(png|jpe?g|gif|mp4)$/);
      const image = imagesContext.keys().find(key => key.includes(imageName));
      if (!image) {
        console.info('[ContentModal] setServiceMarkerMedia image not found: ', imageName);
        return '';
      }
      return imagesContext(image);
    },
    loadImageFromAssets(imageName) {
      const imagesContext = require.context('../assets/static/content/media/', true, /\.(png|jpe?g|gif|mp4)$/);
      const image = imagesContext.keys().find(key => key.includes(imageName));
      if (!image) {
        console.info('[ContentModal] loadImageFromAssets image not found: ', imageName);
        return '';
      }
      return imagesContext(image);
    },
    getFullImageUrl(imagePath) {
      if (imagePath.startsWith('service/')) {
        return this.loadImageFromAssets(imagePath);
      }
      if (process.env.VUE_APP_OFFLINE_BUILD === 'true') {

        const imagesContext = require.context('../assets/static/content/media/', true, /\.(png|jpe?g|gif|mp4)$/);

        const image = imagesContext.keys().find(key => key.includes(imagePath));
        if (!image) {
          console.info('[ContentModal] getFullImageUrl image not found: ', imagePath);
          return '';
        }
        console.log('[ContentModal] getFullImageUrl image: ', image);
        return imagesContext(image);
      } else if (typeof imagePath === 'string') {
        return process.env.VUE_APP_BASE_TYPO3_URL + imagePath;
      } else {
        console.info('[ContentModal] imagePath is no string:', imagePath);
        return '';
      }
    },
    closeModalIfOutsideClick(event) {
      const modalElement = this.$el;
      if (!modalElement.contains(event.target)) {
        this.closeModal();
      }
    },
    setImgRef(el) {
      if (el) {
        this.$nextTick(() => {
          const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                entry.target.style.opacity = 1;
                entry.target.style.transform = 'translateY(0)';
              }
            });
          }, {
            threshold: 0.1
          });

          observer.observe(el);
        });
      }
    },
    startVisibleVideos() {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const video = entry.target;
            video.play().catch(error => {
              console.error("Error trying to play the video:", error);
            });
          }
        });
      }, {
        threshold: 0.1
      });

      const videoElements = this.$refs;
      Object.keys(videoElements).forEach(ref => {
        console.log('ref: ', ref);
        if (ref.startsWith('videoElement')) {
          const video = videoElements[ref];
          if (video instanceof HTMLVideoElement) {
            observer.observe(video);
          }
        }
      });
    },
    resetVideo() {
      const videoElements = this.$refs;
      Object.keys(videoElements).forEach(ref => {
        if (ref.startsWith('videoElement')) {
          const video = videoElements[ref];
          if (video instanceof Element) {
            video.pause();
            video.currentTime = 0;
          }
        }
      });
    },
    closeModal() {
      this.$emit('close');
    },
  },
  watch: {
    isVisible(newVal) {
      if (newVal) {
        this.startVisibleVideos();
      } else {
        this.resetVideo();
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.closeModalIfOutsideClick);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeModalIfOutsideClick);
  }
}
</script>

<style lang="scss">
.content-modal {
  width: 40vw;
  overflow: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: white;
  padding: 20px 40px 30px 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  transition: all .4s ease;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.25);
  visibility: hidden;
  pointer-events: none;

  @media (max-width: 1024px) {
    width: 90%;
    height: 90vh;
    position: fixed;
    left: 50%;
    top: 100%;
    transform: translate(-50%, 0) scale(1);
    transition: opacity 0.4s ease, transform 0.4s ease, top 0.4s ease;
    padding: 15px;
  }

  &.active {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
    visibility: visible;
    pointer-events: auto;

    @media (max-width: 1024px) {
      top: 20%;
      transform: translate(-50%, 0) scale(1);
    }
  }

  .step-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid #fff;
    color: white;
    font-size: 1.2em;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    flex-shrink: 0;

    .step-circle-inner {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80%;
      height: 80%;
      border-radius: 50%;
      background-color: transparent;
      color: $primary-blue;
      font-weight: 700;
      font-size: 1.2rem;
    }
  }

  .content-modal-header {
    margin-left: 20px;

    h2 {
      font-weight: normal;
      color: $text-blue;
      margin: 0;
    }
  }

  &.goods-flow {
    background-color: $primary-yellow;
    color: black;

    .close-button {
      svg path {
        stroke: $primary-blue;
      }

      &:hover {
        background-color: transparent;

        svg path {
          stroke: #000;
        }
      }
    }

    .content-btn {
      border: 1px solid #244e87;
    }
  }

  &.data-flow {
    background-color: $primary-blue;
    color: white;

    .content-modal-header h2 {
      color: white;
    }

    .step-circle {
      border: 3px solid white;
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
      flex-shrink: 0;
      flex-grow: 0;

      .step-circle-inner {
        color: white;
      }
    }

    .close-button {
      svg path {
        stroke: white;
      }

      &:hover {
        svg path {
          stroke: darken(#fff, 20%);
        }

        background-color: transparent;
      }
    }
  }

  &.service-modal {
    background-color: #fff;

    .content-header .step-circle {
      background-color: #fff;
      border: 3px solid #244e87 !important;

      svg#contactIcon {
        width: 30px;
        height: 30px;
      }
    }
  }

  .content-body {
    overflow: auto;
    display: flex;
    flex-direction: column;
    max-height: 70vh;
    box-sizing: border-box;
    padding-right: 77px;
    padding-left: 77px;

    .content-and-first-image {
      flex-direction: column;
    }

    @media (max-width: 579px) {
      max-height: 100vh;
      padding-right: 40px;
      padding-left: 40px;

      .content-and-first-image {
        flex-direction: column;

        .first-image {
          width: auto;
          max-width: 100%;
          margin-left: 0;
          margin-top: 10px;
        }
      }
    }

    .content-and-first-image {
      display: flex;
      justify-content: space-between;

      .content-text {
        margin-bottom: 30px;
        flex: 1;
        color: $text-blue;

        &.data-flow {
          color: white;
        }
      }

      img.first-image {
        width: auto;
        max-width: 100%;
        height: auto;
        object-fit: contain;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;

        @media (max-width: 579px) {
          margin-left: 0;
        }
      }

      video.first-video {
        margin-bottom: 20px;
      }
    }

    .additional-images {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin: 20px auto;

      .content-image {
        width: 100%;
        height: auto;
        opacity: 0;
        transform: translateY(100px);
        transition: opacity 0.8s ease-in-out, transform 0.3s ease-in-out;
      }
    }

    .content-actions {
      a.btn.content-btn {
        box-sizing: border-box;

        &:hover {
          background-color: lighten($primary-blue, 10%);
          border-color: lighten($primary-blue, 10%);
        }

        &:first-child {
          background-color: $primary-yellow;
          border: 1px solid $primary-yellow;
          color: $primary-blue;

          svg path {
            stroke: $primary-blue;
          }

          &:hover {
            background-color: lighten($primary-yellow, 10%);
            border-color: lighten($primary-yellow, 10%);
          }
        }
      }
    }

    @media (max-width: 1024px) {
      padding-bottom: 50px;
    }
  }
}

.inline {
  .content-modal {
    width: 70vw;
  }
}

.modal-wrapper {
  transition: all .4s ease-in-out;

  &.active {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1010;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    display: grid;
    place-items: center;
  }
}
</style>
